<template>
  <div>
    <!-- Page content -->
    <div class="container mt-8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-header bg-transparent pb-4 text-center">
              <img src="/img/brand/brand.png" alt="" class="login__brand">
            </div>
            <div class="card-body px-lg-5 py-lg-5">
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                              class="mb-3"
                              name="username"
                              label="Nazwa użytkownika"
                              :rules="{required: true}"
                              prepend-icon="ni ni-circle-08"
                              placeholder="Użytkownik"
                              v-model="model.username"
                  ></base-input>

                  <base-input alternative
                              class="mb-3"
                              name="password"
                              label="Hasło"
                              :rules="{required: true, min: 6}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Hasło"
                              v-model="model.password"
                  ></base-input>

                  <div class="text-center">
                    <base-button type="primary" native-type="submit" class="my-4">Zaloguj</base-button>
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginPage',
  data() {
    return {
      model: {
        username: '',
        password: ''
      }
    };
  },
  methods: {
    async onSubmit() {
      try {
        const payload = {
          username: this.model.username,
          password: this.model.password,
        };
        const response = await this.$store.dispatch('Auth/AUTH_REQUEST', payload);
        // TODO: wyświetlenie komunikatu błędu w przypadku niepowodzenia
        console.log('RESPONSE', response);
        const {status} = response;
        if (status === 200) {
          return this.$router.push('/dashboard');
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss">
.login__brand {
  max-width: 220px;
}
</style>
